<!-- 留言处理 -->
<template>
    <div>
        <!-- 標題組件 -->
        <!-- <headtitle Otitle="留言处理" />-->
        <el-card class="box-card">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item><a @click="request">首页</a></el-breadcrumb-item>
                <el-breadcrumb-item>留言管理</el-breadcrumb-item>
            </el-breadcrumb>
            <div class="hr-20"></div>
            <div class="tittool fl">
                <el-button type="primary" plain size="mini" @click="batch" :disabled="multipleSelection.length<1">删除</el-button>
            </div>
            <!-- 查詢 -->
            <div class="searchtool fr">
                <el-form :model="ruleForm" ref="ruleForm" label-width="80" class="ruleForm" size="mini" inline>
                    <el-form-item label="筛选：">
                        <el-select v-model="statusvalue" placeholder="请选择" @change="statusfilter">
                            <el-option
                                    v-for="item in options"
                                    :key="item.statusvalue"
                                    :label="item.label"
                                    :value="item.statusvalue">
                            </el-option>
                        </el-select>
                    </el-form-item>
<!--                    <el-form-item>-->
<!--                        <el-input v-model="ruleForm.name" placeholder="请输入关键字"></el-input>-->
<!--                    </el-form-item>-->

<!--                    <el-form-item>-->
<!--                        <el-button type="primary" @click="searchSubmit('ruleForm')">查询</el-button>-->
<!--                    </el-form-item>-->
                </el-form>
            </div>
            <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%"  @selection-change="handleSelectionChange" >
                <el-table-column type="selection" width="55"></el-table-column>
                <el-table-column label="标题" >
                    <template slot-scope="scope">
                        <span @click="seedis(scope.row)" class="info-content">{{scope.row.content | removeHtml}}</span>
                    </template>
                </el-table-column>
                <el-table-column label="留言时间" width="120">
                    <template slot-scope="scope">{{ scope.row.createTime | formatDate}}</template>
                </el-table-column>
<!--                <el-table-column label="留言人" width="120">-->
<!--                    <template slot-scope="scope">-->
<!--                        <span>{{scope.row.userId}}</span>-->
<!--                    </template>-->
<!--                </el-table-column>-->
<!--                <el-table-column label="联系方式" width="120">-->
<!--                    <template slot-scope="scope">{{ scope.row.userId}}</template>-->
<!--                </el-table-column>-->
                <el-table-column label="状态" width="120">
                    <template slot-scope="scope">
<!--                        <span v-html="scope.row.status==1?'已回复':'<b>待回复</b>'"></span>-->
                        <el-tag type="success" v-if="scope.row.status==1">已办结</el-tag>
                        <el-tag type="warning" v-else-if="scope.row.status==0">待办结</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="120">
                    <template slot-scope="scope">
                        <el-button type="primary" size="mini" @click="handleEdit(0, scope.row)" v-if="scope.row.status==0" icon="el-icon-edit-outline" style="color: #f0f0f0">回复</el-button>
                        <el-button type="success" size="mini" @click="handleEdit(1, scope.row)" v-else icon="el-icon-view" style="color: #f0f0f0">查看</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination v-if="total" background layout="prev, pager, next" :total="total" :page-size="pageSize" @current-change="requestPage" id="pagination"/>
        </el-card>
        <el-dialog title="详情" :visible.sync="createdialog"  width="40%" :before-close="handleClose">

            <el-card shadow="never" class="msgbox">
<!--                <div class="hd">-->
<!--                    <span class="fl"> 留言人：{{name}}</span>-->
<!--                    <span class="fr"> 联系方式：{{phone}}</span>-->
<!--                </div>-->
                <el-row :gutter="0">
<!--                    <el-col :span="4"><div class="grid-content bg-purple">留言人：</div></el-col>-->
<!--                    <el-col :span="20"><div class="grid-content bg-purple">{{name}}</div></el-col>-->
                    <el-col :span="24">
                        <div class="titles">

                            <div><span>留言人：</span>{{name}}</div>
                            <div><span>联系方式：</span>{{phone}}</div>

                        </div>

                    </el-col>
                </el-row>
<!--                <el-row :gutter="0">-->

<!--                    <el-col :span="4"><div class="grid-content bg-purple">联系方式：</div></el-col>-->
<!--                    <el-col :span="20"><div class="grid-content bg-purple">{{phone}}</div></el-col>-->
<!--                </el-row>-->
                <div class="bd">
                    <div class="hftit">内容详情：</div>
                    <el-row :gutter="0">
                        <el-col :span="24"><div class="grid-content bg-purple" v-html="content"></div></el-col>
                    </el-row>
                    <div class="hftit">回复内容：</div>
                    <el-row :gutter="0">
                        <el-col :span="24"><div class="grid-content bg-purple" >
                            <el-form v-if="hufchak==0">
                                <el-form-item>
                                    <!--                            <el-input type="textarea"  style="resize:none;" ></el-input>-->
                                    <textarea class="textarea" v-model.trim="reply.reply" placeholder="请输入回复内容" rows="5"></textarea>
                                    <el-alert title="注：请不要超过200字" type="error" class="errs"></el-alert>
                                </el-form-item>
                            </el-form>
                            <div class="cenbox" v-else>
                                {{liuyan}}
                            </div>
                        </div></el-col>
                    </el-row>
                </div>


            </el-card>

            <span slot="footer" class="dialog-footer" v-show="hufchak==0">
                <el-button @click="handleClose">取 消</el-button>
                <el-button type="primary" @click="searchSubmit">确 定</el-button>
            </span>
        </el-dialog>

    </div>
</template>

<script>
    //import headtitle from "@/components/ControlPanel/headtitle";
    export default {
        components: {
            //headtitle

        },
        data() {
            return {
                hufchak:0,
                liuyan:'',
                page: 1,
                pages: 0,
                pageSize: 10,
                curr:1,
                total: 1,
                createLoading: false,
                createdialog:false,
                tableData: [],
                multipleSelection: [],
                ruleForm: {
                    name: ""
                },
                name:'',
                phone:'',
                content:'',
                reply:{
                    id: 0,
                    reply: "",
                    status: 0,
                },
                options: [
                    {statusvalue: -1,
                        label: '全部'
                    },{
                    statusvalue: 0,
                    label: '待办结'
                }, {
                    statusvalue: 1,
                    label: '已办结'
                }],
                statusvalue: ''

            };
        },
        created() {
            this.getDate(); //初始化 数据
        },
        methods: {
          request() {
            let newPage = this.$router.resolve({
              name: 'EntryStoma',
            })
            window.open(newPage.href, '_blank')
          },
            handleSelectionChange(val) {
              const self = this
                this.multipleSelection =[]
                val.forEach(function (element) {
                    self.multipleSelection.push(element.id);
                })
                console.log(this.multipleSelection)
            },
            batch(){
                const self = this
                this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    if (self.multipleSelection.length>1){
                        this.$axios.delete('/feedback/batch',{
                            data:{feedbackIds:self.multipleSelection}
                        }).then(function (res) {
                            console.log(res.data.status)
                            if (res.data.status==200){
                                self.getDate();
                                self.$root.success("批量成功删除"+self.multipleSelection.length+"条")
                            }
                        })
                        return;
                    }
                    let Id =self.multipleSelection[0]
                    this.$axios.delete('/feedback?id='+Id).then(function (res) {
                        console.log(res.data.status)
                        if (res.data.status==200){
                            self.getDate();
                            self.$root.success("删除成功")
                        }
                    })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                });





            },
            getDate() {
                var self = this;
                this.$axios
                    .get("/feedback/page", {
                        params: {
                            current:1,
                            size: this.pageSize,
                        }
                    }).then(function (res) {
                        self.tableData = res.data.data.records;
                        self.total = res.data.data.total;
                    console.log(res.data.data.records)
                    }).catch(function (error) {
                        console.error(error);
                    });
            },

            requestPage(value) {
                var self = this;
                this.curr = value
                this.$axios.get('/feedback/page', {
                    params: {
                        current: this.curr,
                        size: this.pageSize,
                        status:this.statusvalue
                    }
                }).then(response => {
                    self.tableData = response.data.data.records;
                    self.total = response.data.data.total;
                }).catch(error => {
                    console.error(error)
                })
            },

            statusfilter(){
                console.log(this.statusvalue)
                var self = this;
                this.$axios.get('/feedback/page', {
                    params: {
                        current: 1,
                        size: this.pageSize,
                        status:this.statusvalue
                    }
                }).then(response => {
                    self.tableData = response.data.data.records;
                    self.total = response.data.data.total;
                }).catch(error => {
                    console.error(error)
                })
            },
            //查看
            seedis(row){

            },

            //获取信息 名字 电话
            getnameTel(index,row){
              console.log(row)
                const self = this;
                if (row.userType=='talent'){
                    this.$axios.get('/talent',{
                        params:{
                            id:row.userId
                        }
                    }).then(function (res) {
                        if (res.data.status==200){

                            self.name = res.data.data.name
                            self.phone = res.data.data.cellphone
                        }
                    })
                }else {
                    this.$axios.get('/enterprise',{
                        params:{
                            id:row.userId
                        }
                    }).then(function (res) {
                        if (res.data.status==200){
                            self.name = res.data.data.name
                            self.phone = res.data.data.cellphone
                        }
                    })
                }
            },
            //回复
            handleEdit(index, row) {
              const self = this;
                self.hufchak = index
                //console.log(row.content)
                self.content = row.content;
                self.getnameTel(index,row)
                self.reply.id = row.id;
                self.liuyan= row.reply

                self.createdialog = true;
            },
            //关闭回复
            handleClose(){
                const self = this;
                self.createdialog = false;
                self.content = '';
                self.reply.reply = '';
                self.name =  '';
                self.phone =  '';
                self.liuyan = '';


            },
            //提交回复
            searchSubmit() {
                const self = this;
                self.reply.status = 1;
                this.$confirm('确认此回复吗?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    console.log(self.reply)
                    if(self.reply.reply){
                        self.$axios.put('/feedback',self.reply).then(function (res) {
                            if (res.data.status==200){
                                self.createdialog = false;
                                self.reply.reply = '';
                                self.name =  '';
                                self.phone =  '';
                                self.getDate();
                                self.$message({
                                    type: 'success',
                                    message: '留言成功!'
                                });
                            }
                            else {
                                //console.log(res.data.data.reply)
                                self.$message({
                                    type: 'info',
                                    message: '回复失败！'+res.data.data.reply
                                });
                                self.reply.reply = '';
                            }
                        })
                    }else {
                        self.$message({
                            type: 'info',
                            message: '回复失败！内容不能为空'
                        });
                    }
                }).catch(() => {
                    return false
                });



            }
        },

    };
</script>

<style lang="less" scoped>
    #pagination {
        margin: 32px 0 16px 0;
    }
    .info-content {
        width: 100%; /*根据自己项目进行定义宽度*/
        overflow:hidden;
        text-overflow:ellipsis;
        -o-text-overflow:ellipsis;
        -webkit-text-overflow:ellipsis;
        -moz-text-overflow:ellipsis;
        white-space:nowrap;
    }
    .msgbox{
        .hd{
            overflow: hidden;
            padding-bottom: 20px;
            span{
                display: block;

            }
        }
        .titles{
            text-align: left;
            line-height: 38px;
            border: 1px #ddd solid;
            border-bottom: 0;

            font-size: 15px;
            div{
                border-bottom: 1px #ddd solid;
                padding: 0 10px;
                overflow: hidden;
                span{
                    display: block;
                    float: left;
                    width: 80px;
                }
            }
        }
        .bd{
            .hftit{
                display: block;
                padding: 5px 10px;
                color: #333;
                text-align: left;
                border: 1px #ddd solid;
                border-bottom: 0;
            }
            .cenbox{
                text-align: left;
            }
            .errs {
                background: #fff;
                height: 20px;
                padding: 0;
            }
            .content{text-align: left}
        .textarea{
            width: 99%;
            margin-top: 3px;
            resize: none;
            outline:none;
            border: 1px #ddd solid;
        }
        }
            .el-row {
            margin-bottom: 20px;
            &:last-child {
                margin-bottom: 0;
            }
        }
        .el-col {
            border-radius: 4px;
        }
        .bg-purple-dark {
            background: #99a9bf;
        }
        .bg-purple {
            /*background: #d3dce6;*/
            border: 1px #ddd solid;
        }
        .bg-purple-light {
            background: #e5e9f2;
        }
        .grid-content {
            //border-radius: 4px;
            min-height: 20px;
            line-height: 20px;
            text-align: left;
            padding: 10px;
        }
        .row-bg {
            padding: 10px 0;
            background-color: #f9fafc;
        }


    }

</style>